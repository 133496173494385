
import { ref } from 'vue';

import Card from 'primevue/card';

export default {
  components: {
    Card,
  },

  setup() {
    const items = ref([
      {
        id: 0,
        label: 'Профиль',
        to: '/services/profile',
        icon: 'pi pi-fw pi-user',
      },
      {
        id: 1,
        label: 'Экспорт',
        to: '/services/export',
        icon: 'pi pi-fw pi-eject',
      },
      {
        id: 2,
        label: 'Импорт',
        to: '/services/import',
        icon: 'pi pi-fw pi-download',
      },
    ]);
    return {
      items
    }
  }
}
